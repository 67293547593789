import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

const Header = ({ siteTitle }) => (

  <div className="application">

    <Helmet bodyAttributes={{
      class: 'granjatec-app'
    }}>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>GranjaTecApp</title>
      <link rel="manifest" href="/css/manifest.json" />
      <link rel="apple-touch-icon" href="/images/granjatec-icon.png" />
      <meta name="theme-color" content="#00203D" />
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="apple-mobile-web-app-status-bar-style" content="#00203D"/>
    </Helmet>
  </div>
)
   

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
