import React from 'react'
import { isLoggedIn,getUser,logout } from "../services/auth"
import { Link } from 'gatsby'
import { navigate } from "gatsby"

const Menu = props => {
  
    function signOut(event){
        event.preventDefault();
          logout()
          navigate("/login")        
      }

    /* Nivell: 
            1. Access a tot
            2. Acces a sols destete engreix
            3. Acces a sols destete mares 
        */
    return (
        <div className="menu">
            <div className="menu-wrapper">
                <div className="menu-nav">
                    <ul>
                    {getUser() && getUser().nivell.maredes? (
                      <li> <Link className="menu-item" to="/maresDestete">Mares destete</Link></li>
                    ):
                    <span/>
                  }
                    {getUser() && getUser().nivell.deseng? (
                     <li><Link className="menu-item" to="/desteteEngreix" >Destete a engreix</Link></li>
                    ):
                      <span/>
                   }
                   
                   {getUser() && getUser().nivell.engescor? (
                          <li><Link className="menu-item" to="/sortidaEscorxador" >Engreix a escorxador</Link></li>
                    ):
                    <span/>
                  }
                   {getUser() && getUser().nivell.mareescor? (
                         <li><Link className="menu-item" to="/maresEscorxador" >Mares a escorxador</Link></li>
                    ):
                    <span/>
                  }
                   {getUser() && getUser().nivell.fitxesmares? (
                           <li><Link className="menu-item" to="/fitxesMares" >Fitxa granja mares</Link></li>
                    ):
                    <span/>
                  }
                   {getUser() && getUser().nivell.veterinari? (
                       <li> <Link className="menu-item" to="/veterinaris/visitesVeterinaris">Visites veterinari</Link></li>
                      ):
                      <span/>
                    }
                    {getUser() && getUser().nivell.baixes? (
                        <li><Link className="menu-item" to="/baixes">Baixes</Link></li>
                      ):
                      <span/>
                    } 
                     {getUser() && getUser().nivell.admin? (
                        <li><Link className="menu-item" to="/administrador/menuAdmin">Administrator</Link></li>
                      ):
                      <span/>
                    } 

                      <li><Link className="menu-item" to="#" onClick={signOut}>Sortir</Link>  </li>
                    </ul>
                </div>
                <div className="menu-user">
                    <div className="menu-user-avatar">
                        <img src="/images/useravatar-dummy.png" alt="username avatar"/>
                    </div>
                    <div className="menu-user-info">
                        { isLoggedIn()  && 
                        <span>
                            <span className='menu-user-name'>{getUser().nom}</span>
                            <span className='menu-user-company'>{getUser().company}</span>
                        </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu