import React, {useState} from "react"
import { isLoggedIn,getUser,logout } from "../services/auth"
import classNames from "classnames"
import Menu from "../components/menu"

const HeaderMenu = props => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleMenuIsOpen = () => setMenuIsOpen(!menuIsOpen);

  return (
    <header className={menuIsOpen ? classNames('header', 'menu-open') : classNames('header')}>
      <div className="header-wrapper">
        <h1><a href="/">GranjaTec</a></h1>
        { isLoggedIn() && (
          <button id="btn-menu" className="btn-menu" onClick={handleMenuIsOpen}><span>Menu</span><i className="icon-menu"></i></button>
        )
        }
      </div>
      <Menu/>
    </header>
  )
}

export default HeaderMenu