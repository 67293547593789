import React from "react"

const Footer = props => {

  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-company-logo">
        <a className="menu-item" to="http://www.isoft24.com" target="_blank"><img src="../../images/independentsoft-logo.png" alt="Independent Soft"/></a>
        </div>
        &copy; 2021 GranjaTec
      </div>
    </footer> 
  )
}

export default Footer